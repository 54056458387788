import React, { useEffect, useState } from 'react';
import { Chapters } from './Chapters';
import { Comments } from './Comments';
import { LeftMenu } from './LeftMenu';
import '../../assets/css/components/book.css'
import { TableOfContentsHard } from './TableOfContentsHard'
import { connect } from 'react-redux';
import { SERVER } from '../../actions/helper';
import axios from 'axios';
import Modal from 'react-modal';
import { login } from '../../actions/auth';
import { loadImage } from 'browser-image-compression';
import Spinner from '../layout/Spinner';
import { BooksSold } from './BooksSold';

const PropTypes = require('prop-types');

function Book({ login, isAuthenticated }) {
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [isPurchaseModalOpen, setIsPurchaseModalOpen] = useState(false);
	const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [email, setEmail] = useState();
	const [booksSoldTotal, setBooksSoldTotal] = useState();
	const [booksSoldToday, setBooksSoldToday] = useState();

	const onEnterLogin = () => {
		setIsLoginModalOpen(false);
		login({ email: email, password: 'claim-already-purchased' });
	};

	useEffect(() => {
		const checkRedirect = async () => {
			// Check to see if this is a redirect back from Checkout
			const email = await localStorage.getItem('readcanadabook');
			const query = new URLSearchParams(window.location.search);

			if (!email) {
				setLoading(false);
				return;
			}

			if (query.get("success")) {
				console.log("# Order placed!");
				login({ email: email, password: 'book-purchase-success' });
			}

			if (query.get("# canceled")) {
				console.log("Order canceled");
				setLoading(false);
			}
		};

		checkRedirect();
	}, []);

	useEffect(() => {
		function updatePageCounter() {
			const pageCounterDiv = document.getElementById("page-counter");

			if (!pageCounterDiv) {
				return;
			}

			pageCounterDiv.innerHTML = Math.round(window.pageYOffset / window.innerHeight) + 1;
		}

		window.onload = updatePageCounter;
		window.onscroll = updatePageCounter;
	}, []);

	useEffect(() => {
		const getBooksSold = async () => {
			try {
				const config = {
					headers: {
						'Content-Type': 'application/json',
					},
				};
				const res = await axios.get(`${SERVER}/api/users/count`, null, config);
				setBooksSoldTotal(res?.data?.total);
				setBooksSoldToday(res?.data?.today);
			} catch (error) {
				console.log(error);
			}
		}
		getBooksSold();
	}, []);

	const onEmailChanged = (e) => {
		const newEmail = e.target.value;
		setEmail(newEmail);
	}

	const onEnterStripe = () => {
		localStorage.setItem('readcanadabook', email);
	}

	if (loading) {
		return (
			<Spinner />
		)
	}

	return (
		<>
			<div id="book-page-container">
				<div className="cover">
					<h1>Lies About Immigration</h1>
                    <h3 style={{ marginBottom: '2rem', color: 'white' }}>How developed countries lure immigrants to rip them off</h3>
                    <h4 style={{ color: 'white' }}>exposed by a recent immigrant to Canada</h4>
					{!isAuthenticated &&
						<>
							<button type="submit" className="buy-button mb-4" onClick={() => setIsPurchaseModalOpen(true)}>
								Buy the e-book for <s>$19.98</s><span className="book-price-active">$9.99</span>
							</button>
							<BooksSold total={booksSoldTotal} today={booksSoldToday} />
							<div style={{ color: 'white' }}>
								Already purchased?
								<button className="login-button" onClick={() => setIsLoginModalOpen(true)}>Login here</button>
							</div>
						</>
					}
					<div className="last-updated mb-2">Last updated: September 8, 2024</div>
					<div className="last-updated mb-4">For feedback: readcanadabook@gmail.com</div>
					{isAuthenticated && (
						<a
							href='https://drive.google.com/file/d/1ZgnZ1FAbj0NBfpkFUIhGVZpsN3eqDXWS/view?usp=share_link'
							style={{ marginTop: '1rem' }}
						>
							<button className="login-button">Download PDF</button>
						</a>
					)}
				</div>
				<div className="book-container">
					<div id="page-counter"></div>
					{!isMenuOpen ?
						(
							<div id="menu-button-open" onClick={() => setIsMenuOpen(true)}>
								<i className="fas fa-chevron-right" />
							</div>
						) :
						(
							<div id="menu-button-close" onClick={() => setIsMenuOpen(false)}>
								<i className="fas fa-chevron-left" />
							</div>
						)
					}
					<div id="page-up" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
						<i className="fas fa-chevron-up" />
					</div>
					{isMenuOpen && <LeftMenu isAuthenticated={isAuthenticated} />}

					<TableOfContentsHard isAuthenticated={isAuthenticated}/>
					<Chapters isAuthenticated={isAuthenticated}/>

					{isAuthenticated && <Comments />}
					<div className="chapter" id="chapter-44">
						<h2>Epilogue. Pros and Cons of Canada.</h2>
						<br/>
						<h3>Cons:</h3>
						<ul>
							<li>
								High cost of living;
							</li><li>
								Extremely high housing costs;
							</li><li>
								High taxes;
							</li><li>
								Low salaries;
                            <li>No Health Care;</li>
							</li><li>
								Massive influx of over-qualified immigrants;
							</li><li>
								Lack of jobs that pay high enough to cover the high cost of living;
							</li><li>
								Reckless government spending leading to high inflation;
							</li><li>
								People have a "heads in the sand" mentality about politics and the economy;
							</li><li>
								Falling standards of living for young generations and new immigrants;
							</li><li>
								Government policies that tax to the point of having nothing left;
							</li><li>
								Cold climate.
							</li>
						</ul>
                        <h3>Pros:</h3>
                        <p>
                            Regarding the pros, it gets really interesting, as I wrote the following list in 2022:
                        </p>
						<ul>
							<li>
								A wide range of immigration programs;
							</li><li>
								Easier to obtain permanent residency and citizenship than in the US;
							</li><li>
								Canadian passport can provide greater access to the "free world";
							</li><li>
								Easy to adapt, as it is a country of immigrants;
							</li><li>
								Friendly people;
							</li><li>
								Good infrastructure;
							</li><li>
								Family-friendly;
							</li><li>
								Environmentally-friendly;
							</li><li>
								Relatively safe, with low crime rates;
							</li><li>
								Beautiful nature;
							</li>
						</ul>
                        <p>However, while reading this about two years later, in 2024, I would reduce the list of pros to just the following:</p>
                        <ul>
                            <li>
                                Far from wars;
                            </li>
							<li>
								Beautiful nature.
							</li>
						</ul>
                        <p>As you see, I've added "far from wars" as a pro, as the wolrd is getting more and more dangerous with wars starting left and right.</p>
                        <p>I also removed a bunch of other things, as I no longer consider them pros, but rather neutral or even cons.</p>
                        <p>What's the advantage in having a Canadian passport if taxes eat all your income and you can't even afford to travel outside of Canada?</p>
                        <p>What the adventage of having many immigration programs if I've discovered that they are all designed to enslave people?</p>
                        <p>What's the adventage of a environmental laws if you can't even afford to buy food?</p>
                        <p>I no longer view Canadian infrustructure as a good one. It might have been good 30 years ago, but it is not the case anymore.</p>
                        <p>But the natire is still beautiful.</p>
						<p>I enjoy my time in Canada, I met many great people here, there are some beautiful parks here, but it is not worth to losing financial freedom and opportunities that many immigrants have in developing countries.
</p>
						<br/><br/><br/><br/>
						<p>Vancouver, Canada. Last updated: 8, 2024.</p>
						<div className="last-updated mb-4">For feedback: readcanadabook@gmail.com</div>
					</div>
				</div>
			</div>
			<div id='testtest' style={{ display: 'flex', flexDirection:' row', justifyContent: 'center', alignItems: 'center' }}>
				<Modal isOpen={isPurchaseModalOpen} className="stripe-modal">
					<div className='buy-modal-close-button-container'>
						<div className='buy-modal-close-button' onClick={() => setIsPurchaseModalOpen(false)}>
							<i className="fas fa-times fa-2x"></i>
						</div>
					</div>
					<form action={`${SERVER}/api/stripe/create-checkout-session`} method="POST" className='buy-modal-container'>
						<h3>Please enter your email</h3>
						<input
							type='email'
							name='email'
							id='email'
							value='email'
							onChange={onEmailChanged}
							required
							placeholder='Enter your email'
							value={email}
							className='buy-email-input'
						/>
						<button type="submit" className="start-button mb-4" onClick={onEnterStripe}>
							Start reading now
						</button>
					</form>
				</Modal>
				<Modal isOpen={isLoginModalOpen} className="stripe-modal">
					<div className='buy-modal-close-button-container'>
						<div className='buy-modal-close-button' onClick={() => setIsLoginModalOpen(false)}>
							<i className="fas fa-times fa-2x"></i>
						</div>
					</div>
					<div className='buy-modal-container'>
						<h3>Please enter your email</h3>
						<input
							type='text'
							name='email'
							value='email'
							onChange={onEmailChanged}
							required
							placeholder='Enter your email'
							value={email}
							className='buy-email-input'
						/>
						<button type="submit" className="start-button mb-4" onClick={onEnterLogin}>
							Start reading now
						</button>
					</div>
				</Modal>
			</div>
		</>
	);
};

Book.propTypes = {
	login: PropTypes.func.isRequired,
	isAuthenticated: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
	isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { login })(Book);
