import { indexOf } from 'lodash';
import React from 'react';

const partsIndexes = [1, 12];
const unauthChapters = [1, 2, 45];
const chapters = [
			"Preface",
			"Myth #1. Developed countries need immigrants to fill in the jobs that are vacant because of the low birth rates and population decline",
			"Myth #2. Higher GDP per capita means higher quality of life.",
			"Myth #3. Any democratic country is always better than any non-democratic one.",
			"Myth #4. You should move to a developed country for your children.",
			"Myth #5. In developed countries human life is the most precious thing.",
			"Myth #6. You can always go back. It’s better to try immigrating and be disappointed than regret that you haven’t tried.",
			"Myth #7. Developed countries posses stable financial systems that allow to be safe from inflation and currency devaluation.",
			"Myth #8. Despite all downsides, people are still moving to developed countries, therefore they are better.",
			"Myth #9. Democratic countries are examples of meritocracy, where the best people are elected to rule.",
			"Myth #10. If you are against immigration, you are being racist.",
            "Myth #11. There is freedom of speech in western countries.",
			"What’s the solution?",
			"Canadian Dream",
			"A brief overview of my background",
			"Why Canada",
			"How we immigrated",
			"First impressions and the pros of Canada",
			"What is wrong with Canada",
			"My software engineering journey",
			"Moving to Calgary?",
			"Root causes of the problems of Canada",
			"Healthcare in Canada",
			"Politics and the government of Canada",
			"Why people are still coming to Canada",
			"What Canadian immigration program is really about",
			"Our biggest mistake",
			"The price of real estate in Canada always goes up",
			"Commentators: Stop whining and work harder",
			"Commentators: Property prices and rentals are high everywhere",
			"Commentators: You just don’t know how to budget",
			"If not Canada, then where?",
			"My thoughts on the next steps",
			"Do I think immigrating to Canada is still a Good Idea?",
			"How to fix broken Canada in 3 'easy' steps",
			"Why I am against mass immigration or am I?",
			"Example of the cost of living in Canada",
			"A note on the basement culture in Canada",
			"A note on my blogging story",
			"A note on sports and activities for children and adults",
			"A note on how to make friends",
			"The problem with IELTS",
			"How to pass the IELTS exam",
			"What other people are saying",
			"Epilogue: pros and cons of Canada."
];

const Chapter = ({ text, index, isAuthenticated }) => {
	const locked = !isAuthenticated && !unauthChapters.includes(index);
	const hrefLink = `#chapter-${index}`;
	return (
	   <span className="mb-2">
			{locked && <i className="fas fa-lock m-2" />}
			<a
				href={!locked ? hrefLink : null}
				style={{ fontWeight: 'bold'}}
			>
				{index > 0 ? `${index}. ${text}` : text}
			</a>
		</span>
	)
}

export const TableOfContentsList = ({ isAuthenticated }) => {
	return (
		<>
			{chapters.map((text, index) => (
				<>
					{partsIndexes.includes(index) && (
						<h2 style={{ fontWeight: 'bold', marginTop: '0.5rem', color: 'black' }}>Part {indexOf(partsIndexes, index) + 1}</h2>
					)}
					<Chapter key={index} text={text} index={index} isAuthenticated={isAuthenticated} />
				</>
			))}
		</>

	);
};

